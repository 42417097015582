import {combineReducers} from 'redux';
const initialState = {
    menus: [
        {
            name: 'Photographs',
            type: 'all',
            state: 1
        },
        {
            name: 'Streat',
            type: 'street',
            state: 0
        },
        {
            name: 'Night',
            type: 'night',
            state: 0
        },
        {
            name: 'Light',
            type: 'light',
            state: 0
        }
    ],
    currentMenu: 'all'
};
const rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE_MENU':
            const st = Object.assign({}, state)
            st.currentMenu = action.payload;    
            return st;
        default:
            return state;
    }
};
const itemReducer = (state = []) => {
    return state;
}
const allReducers = combineReducers({rootReducer, itemReducer});
export default allReducers;